import { BaseService } from './BaseService';
import { IImagesMessage } from '../../redux/features/images/ImagesState';

export interface IImagesPromptData {
    prompt: string;
    options?: {
        style?: string;
        angle?: string;
        others?: Record<string, string>;
    };
    model_options?: {
        aspect_ratio?: string;
        negative_prompt?: string;
        model?: string;
        number_of_results?: number;
    };
}

export interface IGalleryItems {
    prompt_uuid: string;
    image_uuid: string;
    user: string;
    image_url: string;
    download_url: string;
    thumbnail_url: string;
    gallery_thumbnail_url: string;
    favorite: boolean;
    feedback: null | string;
    date: string;
}

export interface IGalleryResponse {
    items: IGalleryItems[];
    total: number;
    page: number;
    size: number;
    pages: number;
}

export interface IPrompt {
    prompt_uuid: string;
    user: string;
    prompt: string;
    style: string[];
    angle: string[];
    other_prompt_options: Record<string, unknown>;
    aspect_ratio: string;
    negative_prompt: string;
    model: string;
    number_of_results: number;
    date: string;
    images: IImage[];
}

export interface IImage {
    image_uuid: string;
    image_url: string;
    thumbnail_url: string;
    gallery_thumbnail_url: string;
}

export class ImagesService extends BaseService {
    public createImageAsync = async (
        promptData: IImagesPromptData,
        accessToken: string,
        idToken: string,
        signal: AbortSignal,
    ): Promise<IImagesMessage[]> => {
        const body = promptData;

        const result = await this.getResponseAsync<IImagesMessage[] | ReadableStream<Uint8Array>>(
            {
                commandPath: 'api/v1/images',
                method: 'POST',
                body,
            },
            accessToken,
            idToken,
            false,
            signal,
        );

        // Check if result is a ReadableStream<Uint8Array>
        if (result instanceof ReadableStream) {
            // Handle streaming response if needed
            throw new Error('Streaming response handling is not implemented yet.');
        }

        // Return the result directly
        return result;
    };

    public getGalleryAsync = async (
        page: string,
        size: string,
        isFavorite: boolean,
        accessToken: string,
        idToken: string,
        signal: AbortSignal,
    ): Promise<IGalleryResponse> => {
        const queryParams = `?page=${page}&size=${size}&favorite=${isFavorite.toString()}`;

        const result = await this.getResponseAsync<IGalleryResponse | ReadableStream<Uint8Array>>(
            {
                commandPath: `api/v1/images/${queryParams}`,
                method: 'GET',
            },
            accessToken,
            idToken,
            false,
            signal,
        );

        // Check if result is a ReadableStream<Uint8Array>
        if (result instanceof ReadableStream) {
            // Handle streaming response if needed
            throw new Error('Streaming response handling is not implemented yet.');
        }

        return result;
    };

    public setImageFeedback = async (
        promptUuid: string,
        imageUuid: string,
        feedback: 'positive' | 'negative' | null,
        accessToken: string,
        idToken: string,
        signal: AbortSignal,
    ): Promise<void> => {
        const feedbackParam = feedback === 'positive' ? 'true' : feedback === 'negative' ? 'false' : '';

        const queryParams = `?image_uuid=${imageUuid}&feedback=${feedbackParam}`;

        await this.getResponseAsync(
            {
                commandPath: `api/v1/images/${promptUuid}/feedback${queryParams}`,
                method: 'PUT',
            },
            accessToken,
            idToken,
            false,
            signal,
        );
    };

    public setImageFavorite = async (
        promptUuid: string,
        imageUuid: string,
        favorite: boolean,
        accessToken: string,
        idToken: string,
        signal: AbortSignal,
    ): Promise<void> => {
        const queryParams = `?image_uuid=${imageUuid}&favorite=${favorite.toString()}`;

        await this.getResponseAsync(
            {
                commandPath: `api/v1/images/${promptUuid}/favorite${queryParams}`,
                method: 'PUT',
            },
            accessToken,
            idToken,
            false,
            signal,
        );
    };

    public getPromptByUuid = async (
        promptUuid: string,
        accessToken: string,
        idToken: string,
        signal: AbortSignal,
    ): Promise<IPrompt> => {
        const result = await this.getResponseAsync<IPrompt | ReadableStream<Uint8Array>>(
            {
                commandPath: `api/v1/images/${promptUuid}`,
                method: 'GET',
            },
            accessToken,
            idToken,
            false,
            signal,
        );

        // Check if result is a ReadableStream<Uint8Array>
        if (result instanceof ReadableStream) {
            // Handle streaming response if needed
            throw new Error('Streaming response handling is not implemented yet.');
        }

        // Return the result directly
        return result;
    };
}
