import { useEffect, useState } from 'react';
import { Drawer, Box } from '@mui/material';
import { styled } from '@mui/system';
import { ChatList } from './chat/chat-list/ChatList';
import { useAppDispatch, useAppSelector } from '../redux/app/hooks';
import { setIsOpenMobileMenu } from '../redux/features/app/appSlice';
import { RootState } from '../redux/app/store';

const Root = styled(Box)({
    overflow: 'hidden',
    display: 'flex',
    backgroundColor: '#fff',
});

const StyledDrawer = styled(Drawer)(() => ({
    '& .MuiDrawer-paper': {
        backgroundColor: '#030F2B',
        position: 'absolute',
        top: '80px',
        left: '0',
        height: 'calc(100% - 80px)',
        zIndex: '1001',
        border: 'none',
        width: '100%',
        maxWidth: '285px',
    },
}));

const Backdrop = styled('div')({
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: '1000',
});

const DrawerMenu = () => {
    const dispatch = useAppDispatch();
    const { isOpenMobileMenu } = useAppSelector((state: RootState) => state.app);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 744);

    const handleResize = () => {
        setIsMobile(window.innerWidth <= 744);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleBackdropClick = () => {
        dispatch(setIsOpenMobileMenu(false));
    };

    return (
        <Root>
            {isMobile && (
                <StyledDrawer open={isOpenMobileMenu} onClose={handleBackdropClick} variant="persistent">
                    <ChatList />
                </StyledDrawer>
            )}
            {isOpenMobileMenu && isMobile && <Backdrop onClick={handleBackdropClick} />}
        </Root>
    );
};

export default DrawerMenu;
