import { IGalleryResponse, ImagesService, IPrompt } from '../services/ImagesService';
import { useOktaAuth } from '@okta/okta-react';
import { useAppDispatch } from '../../redux/app/hooks';
import {
    setConversationMessage,
    updateConversationMessage,
    updateImagesBotResponseStatus,
    updateMessageSuccess,
} from '../../redux/features/images/imagesSlice';
import { useAbortController } from '../../AbortControllerProvider';

export const useImages = () => {
    const { oktaAuth } = useOktaAuth();
    const dispatch = useAppDispatch();
    const { setController, currentController } = useAbortController();
    const imagesService = new ImagesService();

    const createImageAsync = async (promptData: {
        prompt: string;
        options?: {
            style?: string;
            angle?: string;
            others?: Record<string, string>;
        };
        model_options?: {
            aspect_ratio?: string;
            negative_prompt?: string;
            model?: string;
            number_of_results?: number;
        };
    }): Promise<string | null> => {
        const accessToken = oktaAuth.getAccessToken() ?? '';
        const idToken = oktaAuth.getIdToken() ?? '';

        dispatch(setConversationMessage({ user: promptData, ai: [] }));
        dispatch(updateImagesBotResponseStatus('MaIA is generating'));

        try {
            if (currentController) {
                currentController.abort();
            }

            const newController = new AbortController();
            setController(newController);

            const signal = newController.signal;

            const response = await imagesService.createImageAsync(promptData, accessToken, idToken, signal);

            if (response) {
                dispatch(updateConversationMessage({ user: promptData, ai: response }));
            }
            return null;
        } catch (error) {
            console.error('Error creating image:', error);
            return null;
        } finally {
            dispatch(updateImagesBotResponseStatus(''));
            dispatch(updateMessageSuccess(true));
        }
    };

    const fetchGallery = async (
        page: string,
        size: string,
        isFavorite: boolean,
    ): Promise<IGalleryResponse | undefined> => {
        const accessToken = oktaAuth.getAccessToken() ?? '';
        const idToken = oktaAuth.getIdToken() ?? '';

        try {
            if (currentController) {
                currentController.abort();
            }

            const newController = new AbortController();
            setController(newController);

            const signal = newController.signal;

            const galleryData = await imagesService.getGalleryAsync(
                page,
                size,
                isFavorite,
                accessToken,
                idToken,
                signal,
            );

            if (galleryData) {
                return galleryData;
            }

            return undefined;
        } catch (error) {
            console.error('Error fetching gallery:', error);
            return undefined;
        }
    };

    const setImageFeedback = async (
        promptUuid: string,
        imageUuid: string,
        feedback: 'positive' | 'negative' | null,
    ): Promise<void> => {
        const accessToken = oktaAuth.getAccessToken() ?? '';
        const idToken = oktaAuth.getIdToken() ?? '';

        try {
            if (currentController) {
                currentController.abort();
            }

            const newController = new AbortController();
            setController(newController);

            const signal = newController.signal;

            await imagesService.setImageFeedback(promptUuid, imageUuid, feedback, accessToken, idToken, signal);
        } catch (error) {
            console.error('Error setting image feedback:', error);
        }
    };

    const setImageFavorite = async (promptUuid: string, imageUuid: string, favorite: boolean): Promise<void> => {
        const accessToken = oktaAuth.getAccessToken() ?? '';
        const idToken = oktaAuth.getIdToken() ?? '';

        try {
            if (currentController) {
                currentController.abort();
            }

            const newController = new AbortController();
            setController(newController);

            const signal = newController.signal;

            await imagesService.setImageFavorite(promptUuid, imageUuid, favorite, accessToken, idToken, signal);
        } catch (error) {
            console.error('Error setting image feedback:', error);
        }
    };

    const fetchPromptByUuid = async (promptUuid: string): Promise<IPrompt | null> => {
        const accessToken = oktaAuth.getAccessToken() ?? '';
        const idToken = oktaAuth.getIdToken() ?? '';

        try {
            if (currentController) {
                currentController.abort();
            }

            const newController = new AbortController();
            setController(newController);

            const signal = newController.signal;

            const response = await imagesService.getPromptByUuid(promptUuid, accessToken, idToken, signal);

            return response;
        } catch (error) {
            console.error('Error creating image:', error);
            return null;
        } finally {
        }
    };

    return {
        createImageAsync,
        fetchGallery,
        setImageFeedback,
        setImageFavorite,
        fetchPromptByUuid,
    };
};
