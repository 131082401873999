import { Button, makeStyles, shorthands, tokens } from '@fluentui/react-components';
import React, { useState } from 'react';
import { COPY } from '../../assets/strings';
import { AlertType } from '../../libs/models/AlertType';
import { useAppDispatch, useAppSelector } from '../../redux/app/hooks';
import { RootState } from '../../redux/app/store';
import { addAlert, removeAlert } from '../../redux/features/app/appSlice';
import { editConversationInput } from '../../redux/features/conversations/conversationsSlice';
import { Alerts } from '../shared/Alerts';
import { updateUserIsTyping } from './../../redux/features/conversations/conversationsSlice';

//import pdf from '../../assets/pdf/code_of_use.pdf';
import { Breakpoints } from '../../styles';
import { useAbortController } from '../../AbortControllerProvider';
import { ImagesStatus } from './ImagesStatus';
import { setAngles, setPrompt, setStyles, updateMessageSuccess } from '../../redux/features/images/imagesSlice';
import FilterDialog from './FilterDialog';
import { useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';
import { TextField } from '@mui/material';
import SecondPopover from '../SecondPopover';
import ThirdPopover from '../ThirdPopover';
import FourthPopover from '../FourthPopover';
import { anglesLabels, formatLabels, styleImageWithLabels } from '../../Constants';

const infoMessage = "Wait for MaIA's response before sending a new message";

const useClasses = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        ...shorthands.margin(0, '72px'),
        ...Breakpoints.small({
            ...shorthands.margin(0, '15px'),
        }),
    },
    typingIndicator: {
        minHeight: '28px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'end',
        marginBottom: '8px',
    },
    content: {
        ...shorthands.gap(tokens.spacingHorizontalM),
        display: 'flex',
        position: 'relative',
        flexDirection: 'row',
        width: '100%',
        '& .fui-Textarea:after': {
            display: 'none',
        },
        '& .fui-Textarea': {
            ...shorthands.border('0 !important'),
            paddingBottom: '0',
            ...shorthands.outline('0'),
        },
        '& .fui-Textarea:hover': {
            ...shorthands.border('0', 'solid', 'transparent'),
        },
        '& .fui-Textarea:focus': {
            ...shorthands.border('0', 'solid', 'transparent'),
        },
    },
    input: {
        width: '100%',
    },
    textarea: {
        maxHeight: '230px',
        ...shorthands.padding('12px', '170px', '12px', '12px'),
        ...shorthands.borderRadius('4px', '4px', '4px', '4px'),
        ...shorthands.border('1px', 'solid', '#1A1D20'),
        '&:focus': {
            ...shorthands.borderColor('#9CB0EC'),
            ...shorthands.outline('0'),
        },
    },
    controls: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        color: '#54616D',
        fontFamily: 'LVMH Sans',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: '400',
        ...Breakpoints.small({
            fontSize: '12px',
        }),
    },
    essentials: {
        display: 'flex',
        flexDirection: 'row',
        marginLeft: 'auto',
        position: 'absolute',
        right: '7px',
        bottom: '9px',
        zIndex: '100',
    },
    btn: {
        backgroundColor: '#576DDD',
        ...shorthands.border('0'),
        ...shorthands.borderRadius('50%'),
        maxWidth: '32px',
        minWidth: '32px',
        width: '32px',
        maxHeight: '32px',
        minHeight: '32px',
        height: '32px',
        marginRight: '0',
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '&:hover': {
            backgroundColor: '#576DDD',
            opacity: '0.7',
            '&:active': {
                backgroundColor: '#576DDD',
                opacity: '0.7',
            },
        },
        '&:active': {
            backgroundColor: '#576DDD',
            opacity: '0.7',
        },
    },
    btnAttach: {
        color: '#576DDD',
        fontWeight: 400,
        ...shorthands.padding('0'),
        justifyContent: 'end',
        ...Breakpoints.small({
            minWidth: 'fit-content',
        }),
        '& span': {
            ...Breakpoints.small({
                display: 'none',
            }),
        },
    },
    functional: {
        display: 'flex',
        flexDirection: 'row',
        position: 'absolute',
        right: '60px',
        bottom: '10px',
        zIndex: '100',
        '& svg': {
            width: '24px',
            height: '24px',
        },
    },
    dragAndDrop: {
        ...shorthands.border(tokens.strokeWidthThick, ' solid', tokens.colorBrandStroke1),
        ...shorthands.padding('8px'),
        textAlign: 'center',
        backgroundColor: tokens.colorNeutralBackgroundInvertedDisabled,
        fontSize: tokens.fontSizeBase300,
        color: tokens.colorBrandForeground1,
        caretColor: 'transparent',
    },
    infoButton: {
        ...shorthands.padding(0),
        ...shorthands.margin(0),
        minWidth: 'auto',
        marginLeft: 'auto', // align to right
    },
    menuList: {
        alignItems: 'center',
    },
    menuItem: {
        width: '100%',
        color: '#1A1D20',
        fontSize: '16px',
        '& > span': {
            display: 'flex',
            alignItems: 'center',
            ...shorthands.gap('5px'),
        },
        '&:hover': {
            backgroundColor: 'transparent',
            color: '#576ddd',
        },
        '&:hover:active': {
            backgroundColor: 'transparent',
            color: '#576ddd',
        },
        '&:nth-of-type(2)': {
            ...shorthands.borderBottom('1px', 'solid', '#E0E5F0'),
            ...shorthands.borderTop('1px', 'solid', '#E0E5F0'),
            ...shorthands.borderRadius('0'),
        },
    },
    btnLearn: {
        ...shorthands.borderRadius('20px'),
        ...shorthands.borderColor('#9CB0EC'),
        ...shorthands.padding('4px', '16px'),
        width: '100%',
        maxWidth: '163px',
        color: '#576DDD',
        fontWeight: '400',
        ...shorthands.gap('5px'),
        '&:hover': {
            ...shorthands.borderColor('#576DDD'),
            color: '#fff',
            backgroundColor: '#576DDD',
            '& svg path': {
                fill: '#fff',
            },
        },
        '&:hover:active': {
            ...shorthands.borderColor('#576DDD'),
            color: '#fff',
            backgroundColor: '#576DDD',
            '& svg path': {
                fill: '#fff',
            },
        },
    },
    filterBtns: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'absolute',
        bottom: '10px',
        left: '10px',
        ...Breakpoints.laptop({
            display: 'none',
        }),
    },
});

const StopIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24">
            <g>
                <path fill="white" d="M0 0h24v24H0z"></path>
                <path d="M6 7v10a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V7a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1z" fill="white"></path>
            </g>
        </svg>
    );
};

const SubmitIcon = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.14645 3.5607L2 8.70714L1.29289 8.00004L7.64645 1.64648L14 8.00004L13.2929 8.70714L8.14645 3.5607L8.14645 14.3536L7.14645 14.3536L7.14645 3.5607Z"
            fill="white"
        />
    </svg>
);

const AdjustIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13.5 4.5L11.437 4.5C11.215 3.63739 10.4319 3 9.5 3C8.56808 3 7.78503 3.63739 7.56301 4.5H3.5V5.5H7.56301C7.78503 6.36261 8.56808 7 9.5 7C10.4319 7 11.215 6.36261 11.437 5.5L13.5 5.5V4.5ZM9.5 6C8.94772 6 8.5 5.55228 8.5 5C8.5 4.44772 8.94772 4 9.5 4C10.0523 4 10.5 4.44772 10.5 5C10.5 5.55228 10.0523 6 9.5 6ZM8.43699 11.5C8.21497 12.3626 7.43192 13 6.5 13C5.56808 13 4.78503 12.3626 4.56301 11.5H3.5V10.5H4.56301C4.78503 9.63739 5.56808 9 6.5 9C7.43192 9 8.21497 9.63739 8.43699 10.5L13.5 10.5V11.5L8.43699 11.5ZM7.5 11C7.5 11.5523 7.05228 12 6.5 12C5.94772 12 5.5 11.5523 5.5 11C5.5 10.4477 5.94772 10 6.5 10C7.05228 10 7.5 10.4477 7.5 11Z"
                fill="#576DDD"
            />
        </svg>
    );
};

const CloseIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="11" height="12" viewBox="0 0 11 12" fill="none">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.1465 11.3536L0.146484 1.35359L0.853591 0.646484L10.8536 10.6465L10.1465 11.3536Z"
                fill="#030F2B"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.8536 1.35352L0.853591 11.3535L0.146484 10.6464L10.1465 0.646408L10.8536 1.35352Z"
                fill="#030F2B"
            />
        </svg>
    );
};

const CssTextField = styled(TextField)({
    width: '100%',
    '& label': {
        color: '#54616D',
        top: '-3px',
    },
    '& label.Mui-focused': {
        color: '#1D1D1D',
        top: '0',
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: '#8F8A80',
    },
    '& .MuiOutlinedInput-root': {
        borderRadius: '24px',
        paddingRight: '200px',
        paddingTop: '12.5px',
        paddingBottom: '52.5px',
        background: '#fff',
        height: '100%',
        ...Breakpoints.small({
            paddingRight: '90px',
        }),
        ...Breakpoints.laptop({
            paddingBottom: '12.5px',
        }),
        '& fieldset': {
            borderColor: '#8F8A80',
        },
        '&:hover fieldset': {
            borderColor: '#8F8A80',
        },
        '&.Mui-focused fieldset': {
            borderWidth: '1px',
            borderColor: '#8F8A80',
        },
    },
});

interface ChatInputProps {
    onSubmit: (options: any) => Promise<void>;
}

export const ImagesInput: React.FC<ChatInputProps> = ({ onSubmit }) => {
    const classes = useClasses();
    const dispatch = useAppDispatch();
    const { conversations, selectedId } = useAppSelector((state: RootState) => state.conversations);
    const { activeUserInfo, alerts } = useAppSelector((state: RootState) => state.app);
    const { isMessageSuccess } = useAppSelector((state: RootState) => state.images.conversation);
    const { prompt, options } = useAppSelector((state: RootState) => state.images);
    const { currentController } = useAbortController();
    const { model_options } = useSelector((state: RootState) => state.images);
    const { popovers } = useSelector((state: RootState) => state.popover);
    const shadowClassName = popovers[1] ? 'shadow-zindex popover1' : '';
    const shadowClassName2 = popovers[2] ? 'shadow-zindex popover1' : '';
    const shadowClassName3 = popovers[3] ? 'shadow-zindex popover1' : '';

    const [value, setValue] = useState(prompt);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [open, setOpen] = useState(false);

    const textAreaRef = React.useRef<HTMLTextAreaElement>(null);

    React.useEffect(() => {
        // Function to focus on the text area
        const focusOnTextArea = () => {
            if (textAreaRef.current) {
                const length = textAreaRef.current.value.length;
                textAreaRef.current.focus();
                textAreaRef.current.setSelectionRange(length, length);
            }
        };

        // If the screen matches the media query, focus on the text area
        const mediaQuery = window.matchMedia('(min-width: 745px)');
        const handleMediaQueryChange = () => {
            if (mediaQuery.matches) {
                focusOnTextArea();
            }
        };

        // Initial focus based on media query
        handleMediaQueryChange();

        // Listen for changes in the media query
        mediaQuery.addListener(handleMediaQueryChange);

        // Clean up event listener on component unmount
        return () => {
            mediaQuery.removeListener(handleMediaQueryChange);
        };
    }, [selectedId]);

    React.useEffect(() => {
        if (isMessageSuccess) {
            const alertIndex = alerts.findIndex((obj) => obj.message === infoMessage);
            dispatch(removeAlert(alertIndex));
        }
    }, [isMessageSuccess]);

    React.useEffect(() => {
        setValue(prompt);
    }, [prompt]);

    React.useEffect(() => {
        const chatState = conversations[selectedId];
        setValue(chatState?.disabled ? COPY.CHAT_DELETED_MESSAGE() : chatState?.input);
    }, [conversations, selectedId]);

    const handleSubmit = () => {
        const requestData = {
            prompt: prompt,
            options: {
                style: options.style,
                angle: options.angle,
                others: options.others,
            },
            model_options: model_options,
        };
        dispatch(updateMessageSuccess(false));
        setIsSubmitted(true);
        if (prompt.trim() === '') {
            return; // only submit if value is not empty
        }
        // const hubConnection = getOrCreateHubConnection(store);
        // // handleReceiveMessageUpdate(hubConnection, store);
        // hubConnection
        //     .invoke('AddClientToGroupAsync', selectedId)
        //     .catch((err) => store.dispatch(addAlert({ message: String(err), type: AlertType.Error })));
        dispatch(setPrompt(''));
        setValue('');
        selectedId && dispatch(editConversationInput({ id: selectedId, newInput: '' }));
        // dispatch(updateBotResponseStatus({ chatId: selectedId, status: 'Calling the kernel' }));
        onSubmit(requestData).catch((error: unknown) => {
            const message = `Error submitting chat input: ${(error as Error).message}`;
            dispatch(
                addAlert({
                    type: AlertType.Error,
                    message,
                }),
            );
        });
    };

    const handleStop = () => {
        // const currentMessage = conversations[selectedId].messages.find((message) => message.content === '');
        // if (currentMessage?.id) {
        //     dispatch(
        //         updateMessageProperty({
        //             chatId: selectedId,
        //             messageIdOrIndex: currentMessage.date,
        //             property: 'content',
        //             value: '<Your query have been cancelled>',
        //             frontLoad: true,
        //         }),
        //     );
        // }
        currentController?.abort();
        const alertIndex = alerts.findIndex((obj) => obj.message === infoMessage);
        dispatch(removeAlert(alertIndex));
        setIsSubmitted(false);
        // const hubConnection = getOrCreateHubConnection(store);
        // hubConnection
        //     .invoke('StopConnection', selectedId)
        //     .catch((err) => dispatch(addAlert({ message: String(err), type: AlertType.Error })));
        // hubConnection.off('ReceiveMessageUpdate');
        // hubConnection.on('ReceiveMessageUpdate', () => {});
    };

    return (
        <div className={classes.root}>
            <FilterDialog
                handleClose={() => {
                    setOpen(false);
                }}
                open={open}
            />
            <div className={classes.typingIndicator}>
                <SecondPopover />
                <ThirdPopover />
                <FourthPopover />
                <ImagesStatus />
            </div>
            <Alerts />
            <div className={shadowClassName} id="second-anchor">
                <div className={classes.content}>
                    <div className={`${shadowClassName2} ${classes.functional}`} id="third-anchor">
                        <Button
                            className={classes.btnAttach}
                            appearance="transparent"
                            onClick={() => {
                                setOpen(true);
                            }}
                            title="Fine tune your prompt"
                            aria-label="Fine tune your prompt button"
                            iconPosition="after"
                        >
                            <span>FINE TUNE</span>
                            <AdjustIcon />
                        </Button>
                    </div>
                    <CssTextField
                        label="Write to MaIA"
                        title="Chat input"
                        aria-label="Chat input field. Click enter to submit input."
                        inputRef={textAreaRef}
                        id="chat-input"
                        variant="outlined"
                        multiline
                        maxRows={8}
                        disabled={conversations[selectedId]?.disabled}
                        // className={isDraggingOver ? mergeClasses(classes.dragAndDrop, classes.textarea) : classes.textarea}
                        className={classes.textarea}
                        // value={isDraggingOver ? 'Drop your files here' : value}
                        value={prompt}
                        onFocus={() => {
                            // update the locally stored value to the current value
                            const chatInput = document.getElementById('chat-input');
                            if (chatInput) {
                                setValue((chatInput as HTMLTextAreaElement).value);
                            }
                            // User is considered typing if the input is in focus
                            if (activeUserInfo) {
                                dispatch(
                                    updateUserIsTyping({
                                        userId: activeUserInfo.id,
                                        chatId: selectedId,
                                        isTyping: true,
                                    }),
                                );
                            }
                        }}
                        onChange={(event) => {
                            const newValue = event.target.value;
                            setValue(newValue);
                            dispatch(setPrompt(newValue));
                        }}
                        onKeyDown={(event) => {
                            if (event.key === 'Enter' && !event.shiftKey) {
                                event.preventDefault();
                                if (value.trim() === '') {
                                    return;
                                }
                                if (value?.length > 0 && (!isSubmitted || isMessageSuccess)) {
                                    handleSubmit();
                                } else {
                                    const shouldAddAlert = !alerts.some((obj) =>
                                        obj.message ? obj.message.includes(infoMessage) : false,
                                    );

                                    if (shouldAddAlert) {
                                        dispatch(
                                            addAlert({
                                                message: infoMessage,
                                                type: AlertType.Info,
                                            }),
                                        );
                                    }
                                }
                            }
                        }}
                        onBlur={() => {
                            // User is considered not typing if the input is not  in focus
                            if (activeUserInfo) {
                                dispatch(
                                    updateUserIsTyping({
                                        userId: activeUserInfo.id,
                                        chatId: selectedId,
                                        isTyping: false,
                                    }),
                                );
                            }
                        }}
                    />
                    <div className={classes.filterBtns}>
                        <div style={{ display: 'flex', flexWrap: 'wrap', marginTop: '10px' }}>
                            {(options?.style?.[0] || options?.angle?.[0]) && (
                                <button
                                    onClick={() => {
                                        dispatch(setStyles([]));
                                        dispatch(setAngles([]));
                                    }}
                                    style={{
                                        padding: '4px 16px',
                                        fontSize: '14px',
                                        fontWeight: '300',
                                        color: '#030F2B',
                                        borderRadius: '32px',
                                        backgroundColor: '#FFFFFF',
                                        marginRight: '5px',
                                        cursor: 'pointer',
                                        border: '1px solid #474F6C',
                                        textTransform: 'uppercase',
                                    }}
                                >
                                    Delete All
                                </button>
                            )}
                            {options?.style?.[0] && (
                                <div
                                    onClick={() => {
                                        dispatch(setStyles([]));
                                    }}
                                    style={{
                                        cursor: 'pointer',
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: '5px',
                                        padding: '4px 16px',
                                        fontSize: '14px',
                                        fontWeight: '300',
                                        color: '#030F2B',
                                        borderRadius: '32px',
                                        backgroundColor: '#E0E5F0',
                                        marginRight: '5px',
                                        textTransform: 'uppercase',
                                    }}
                                >
                                    <span>{styleImageWithLabels[options.style[0]].label}</span> <CloseIcon />
                                </div>
                            )}
                            {options?.angle?.[0] && (
                                <div
                                    onClick={() => {
                                        dispatch(setAngles([]));
                                    }}
                                    style={{
                                        cursor: 'pointer',
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: '5px',
                                        padding: '4px 16px',
                                        fontSize: '14px',
                                        fontWeight: '300',
                                        color: '#030F2B',
                                        borderRadius: '32px',
                                        backgroundColor: '#E0E5F0',
                                        marginRight: '5px',
                                        textTransform: 'uppercase',
                                    }}
                                >
                                    <span>{anglesLabels[options.angle[0]]}</span> <CloseIcon />
                                </div>
                            )}
                            {model_options?.aspect_ratio && (
                                <div
                                    style={{
                                        padding: '4px 16px',
                                        fontSize: '14px',
                                        fontWeight: '300',
                                        color: '#030F2B',
                                        borderRadius: '32px',
                                        backgroundColor: '#E0E5F0',
                                        textTransform: 'uppercase',
                                    }}
                                >
                                    {formatLabels[model_options.aspect_ratio]}
                                </div>
                            )}
                        </div>
                    </div>
                    <div className={`${shadowClassName3} ${classes.essentials}`} id="fourth-anchor">
                        {!isSubmitted || isMessageSuccess ? (
                            <Button
                                className={classes.btn}
                                title="Submit"
                                aria-label="Submit message"
                                appearance="transparent"
                                icon={<SubmitIcon />}
                                onClick={() => {
                                    if (value?.length > 0) {
                                        handleSubmit();
                                    }
                                }}
                                size="large"
                                disabled={conversations[selectedId]?.disabled || !value?.length}
                            />
                        ) : (
                            <Button
                                className={classes.btn}
                                title="Stop"
                                aria-label="Stop generating"
                                appearance="transparent"
                                icon={<StopIcon />}
                                onClick={() => {
                                    handleStop();
                                }}
                                disabled={conversations[selectedId]?.disabled}
                            />
                        )}
                    </div>
                </div>
            </div>
            <div className={classes.controls}>
                <div style={{ textAlign: 'center', margin: 'auto' }}>Images generated are for internal use only.</div>{' '}
            </div>
        </div>
    );
};
