import * as React from 'react';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { useAppSelector } from '../redux/app/hooks';
import { RootState } from '../redux/app/store';
import { useDispatch } from 'react-redux';
import { setIsFeaturesModalOpen } from '../redux/features/app/appSlice';

const BootstrapDialog = styled(Dialog)(() => ({
    zIndex: '10000',
    '& .MuiDialogContent-root': {
        padding: '72px',
        '@media (max-width: 744px)': {
            padding: '24px',
        },
    },
}));

const FeaturesModal = () => {
    const { isFeaturesModalOpen } = useAppSelector((state: RootState) => state.app);
    const dispatch = useDispatch();

    const handleClose = () => {
        dispatch(setIsFeaturesModalOpen(false));
    };

    return (
        <React.Fragment>
            <BootstrapDialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={isFeaturesModalOpen}>
                <DialogContent>
                    <div style={{ fontSize: '24px', fontWeight: '700', marginBottom: '40px' }}>
                        New Features & Enhancements
                    </div>
                    <div style={{ fontSize: '20px', fontWeight: '700', marginBottom: '16px' }}>
                        v2.1.0 - 15 October 2024
                    </div>
                    <ul style={{ paddingLeft: '20px', fontSize: '16px', fontWeight: '300', marginBottom: '40px' }}>
                        <li>MaIA can now generate images.</li>
                        <li>Ability to drag and drop documents.</li>
                        <li>Gemini model updated.</li>
                    </ul>
                    <div style={{ fontSize: '20px', fontWeight: '700', marginBottom: '16px' }}>
                        v2.0 - 26 September 2024
                    </div>
                    <ul style={{ paddingLeft: '20px', fontSize: '16px', fontWeight: '300', marginBottom: '40px' }}>
                        <li>Access to internet for up-to-date answers.</li>
                        <li>Model chooser: Benefit from more performant technologies according to your tasks.</li>
                        <li>Query a webpage directly by copying the link.</li>
                        <li>MaIA answers evaluation via feedbacks.</li>
                        <li>Better understanding of complex and large documents</li>
                    </ul>
                    <div
                        onClick={() => {
                            handleClose();
                        }}
                        style={{
                            borderRadius: '28px',
                            background: '#030F2B',
                            color: '#fff',
                            padding: '16px 48px',
                            textAlign: 'center',
                            cursor: 'pointer',
                        }}
                    >
                        RETURN TO MAIA
                    </div>
                </DialogContent>
            </BootstrapDialog>
        </React.Fragment>
    );
};

export default FeaturesModal;
