import { OAuthResponseType } from '@okta/okta-auth-js';

const CLIENT_ID = process.env.REACT_APP_CLIENT_ID ?? '{clientId}';
const ISSUER = process.env.REACT_APP_ISSUER ?? 'https://{yourOktaDomain}/oauth2/default';
const REDIRECT_URI = `${window.location.origin}/login/openid-redirect-uri`;

interface OktaConfig {
    clientId: string;
    issuer: string;
    redirectUri: string;
    scopes: string[];
    pkce: boolean;
    responseType: OAuthResponseType[];
}

const oktaConfig: OktaConfig = {
    clientId: CLIENT_ID,
    issuer: ISSUER,
    redirectUri: REDIRECT_URI,
    scopes: ['openid', 'profile', 'email', 'offline_access'],
    pkce: true,
    responseType: ['id_token', 'token'],
};

export default {
    oidc: oktaConfig,
};
