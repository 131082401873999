import {
    Checkmark20Filled,
    Checkmark20Regular,
    Dismiss16Filled,
    Dismiss16Regular,
    Dismiss20Filled,
    Dismiss20Regular,
    bundleIcon,
} from '@fluentui/react-icons';

export const Checkmark20 = bundleIcon(Checkmark20Filled, Checkmark20Regular);
export const Dismiss16 = bundleIcon(Dismiss16Filled, Dismiss16Regular);
export const Dismiss20 = bundleIcon(Dismiss20Filled, Dismiss20Regular);
