import hljs from 'highlight.js';
import 'highlight.js/styles/github-dark-dimmed.min.css';
import { Persona, makeStyles, mergeClasses, shorthands } from '@fluentui/react-components';
import React from 'react';
import { RootState } from '../../../redux/app/store';
import { Breakpoints, customTokens } from '../../../styles';
import * as utils from './../../utils/TextUtils';
import { ImagesHistoryContent } from './ImagesHistoryContent';
import { IImagesChatMessage } from '../../../redux/features/images/imagesSlice';
import { useAppSelector } from '../../../redux/app/hooks';
import { anglesLabels, formatLabels, styleImageWithLabels } from '../../../Constants';

const useClasses = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'row',
        maxWidth: '75%',
        ...shorthands.borderRadius(customTokens.borderRadiusMedium),
        ...Breakpoints.small({
            maxWidth: '100%',
        }),
        ...shorthands.gap(customTokens.spacingHorizontalXS),
    },
    debug: {
        position: 'absolute',
        top: '-4px',
        right: '-4px',
    },
    alignEnd: {
        alignSelf: 'flex-end',
        flexDirection: 'row-reverse',
        '& .fui-Avatar': {
            marginRight: '0',
            marginLeft: '15px',
        },
        '& .fui-Avatar__initials': {
            backgroundColor: '#576ddd !important',
            color: '#fff',
        },
    },
    persona: {
        paddingTop: customTokens.spacingVerticalS,
        width: '40px',
        height: '40px',
        marginRight: '5px',
        '& .fui-Avatar': {
            fontWeight: '400',
            width: '40px',
            height: '40px',
        },
        '& .fui-Avatar__initials': {
            backgroundColor: '#131F39',
            color: '#fff',
        },
    },
    user: {
        '& .fui-Avatar__initials': {
            backgroundColor: '#984126!important',
            color: '#fff',
        },
    },
    item: {
        backgroundColor: 'none',
        ...shorthands.borderRadius(customTokens.borderRadiusXLarge),
        ...shorthands.padding(customTokens.spacingVerticalS, customTokens.spacingHorizontalL),
    },
    me: {
        backgroundColor: '#FFFFFF',
    },
    server: {
        ...shorthands.border('1px', 'dashed', '#576DDD'),
    },
    time: {
        color: customTokens.colorNeutralForeground3,
        fontSize: customTokens.fontSizeBase200,
        fontWeight: 400,
    },
    header: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'row',
        ...shorthands.gap(customTokens.spacingHorizontalL),
    },
    canvas: {
        width: '100%',
        textAlign: 'center',
    },
    image: {
        maxWidth: '250px',
    },
    blur: {
        filter: 'blur(5px)',
    },
});

interface ChatHistoryItemProps {
    messages: IImagesChatMessage[];
    message: IImagesChatMessage;
    messageIndex: number;
}

export const ImagesHistoryItem: React.FC<ChatHistoryItemProps> = ({ message, messageIndex }) => {
    const classes = useClasses();

    const { selectedId } = useAppSelector((state: RootState) => state.conversations);
    const { activeUserInfo } = useAppSelector((state: RootState) => state.app);

    React.useEffect(() => {
        const codeElements = document.querySelectorAll('code[data-highlighted]');
        codeElements.forEach((element: Element) => {
            if (element instanceof HTMLElement) {
                delete element.dataset.highlighted;
            }
        });
        hljs.configure({ ignoreUnescapedHTML: true });
        hljs.highlightAll();
    }, [message]);

    return (
        <>
            {message.user ? (
                <div
                    className={mergeClasses(classes.root, classes.alignEnd)}
                    // The following data attributes are needed for CI and testing
                    data-testid={`chat-history-item-${messageIndex.toString()}`}
                    data-username={'any'}
                    data-content={utils.formatChatTextContent(message.user.prompt ?? '')}
                >
                    {
                        <Persona
                            className={mergeClasses(classes.persona, classes.user)}
                            avatar={{
                                name: activeUserInfo ? activeUserInfo.username : '',
                                idForColor: selectedId,
                                color: 'colorful',
                            }}
                            presence={undefined}
                        />
                    }
                    <div className={mergeClasses(classes.item, classes.me)}>
                        <div className="line-numbers">
                            <p>{message.user.prompt ?? ''}</p>
                            <div style={{ display: 'flex', flexWrap: 'wrap', marginTop: '10px' }}>
                                {message.user?.options?.style?.[0] && (
                                    <div
                                        style={{
                                            padding: '4px 16px',
                                            fontSize: '14px',
                                            fontWeight: '300',
                                            color: '#030F2B',
                                            borderRadius: '32px',
                                            backgroundColor: '#E0E5F0',
                                            marginRight: '5px',
                                            textTransform: 'uppercase',
                                        }}
                                    >
                                        {styleImageWithLabels[message.user.options.style[0]].label}
                                    </div>
                                )}
                                {message.user?.options?.angle?.[0] && (
                                    <div
                                        style={{
                                            padding: '4px 16px',
                                            fontSize: '14px',
                                            fontWeight: '300',
                                            color: '#030F2B',
                                            borderRadius: '32px',
                                            backgroundColor: '#E0E5F0',
                                            marginRight: '5px',
                                            textTransform: 'uppercase',
                                        }}
                                    >
                                        {anglesLabels[message.user.options.angle[0]]}
                                    </div>
                                )}
                                {message.user?.model_options?.aspect_ratio && (
                                    <div
                                        style={{
                                            padding: '4px 16px',
                                            fontSize: '14px',
                                            fontWeight: '300',
                                            color: '#030F2B',
                                            borderRadius: '32px',
                                            backgroundColor: '#E0E5F0',
                                            textTransform: 'uppercase',
                                        }}
                                    >
                                        {formatLabels[message.user.model_options.aspect_ratio]}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                ''
            )}
            {message.ai?.length ? (
                <div
                    className={classes.root}
                    // The following data attributes are needed for CI and testing
                    data-testid={`chat-history-item-${messageIndex.toString()}`}
                    data-username={'any'}
                >
                    {<Persona className={classes.persona} avatar={{ name: 'M', color: 'navy' }} presence={undefined} />}
                    <div>
                        <div className={classes.item}>
                            <div className="line-numbers">
                                <ImagesHistoryContent message={message.ai} messageIndex={messageIndex} />
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                ''
            )}
        </>
    );
};
