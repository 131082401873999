import React from 'react';
import { addAlert } from '../../../redux/features/app/appSlice';
import { AlertType } from '../../../libs/models/AlertType';
import { useAppDispatch } from '../../../redux/app/hooks';
import CopyLink from '../../../assets/images/CopyLink.svg';
import { Image } from '@fluentui/react-components';

interface CopyLinkProps {
    link: string;
}

const CopyLinkIcon = () => {
    return <Image src={CopyLink} />;
};

const CopyLinkComponent: React.FC<CopyLinkProps> = ({ link }) => {
    const dispatch = useAppDispatch();

    const copyToClipboard = async (message: string) => {
        try {
            await navigator.clipboard.writeText(message);
            dispatch(addAlert({ message: 'Text copied to clipboard', type: AlertType.Success }));
        } catch (error: any) {
            dispatch(addAlert({ message: `Error copying to clipboard: ${String(error)}`, type: AlertType.Error }));
        }
    };

    return (
        <div
            style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', color: '#576DDD' }}
            onClick={() => void copyToClipboard(link)}
        >
            <div className="svg-container">
                <CopyLinkIcon /> COPY
            </div>
        </div>
    );
};

export default CopyLinkComponent;
