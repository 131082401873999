import { makeStyles } from '@fluentui/react-components';
import { Animation } from '@fluentui/react-northstar';
import React from 'react';
import { useAppSelector } from '../../redux/app/hooks';
import { RootState } from '../../redux/app/store';
import { TypingIndicator } from './typing-indicator/TypingIndicator';

const useClasses = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'end',
    },
});

export const ImagesStatus: React.FC = () => {
    const classes = useClasses();

    const { botResponseStatus } = useAppSelector((state: RootState) => state.images.conversation);

    if (botResponseStatus === '') {
        return null;
    }

    return (
        <Animation name="slideInCubic" keyframeParams={{ distance: '2.4rem' }}>
            <div className={classes.root}>
                <label>{botResponseStatus}</label>
                <TypingIndicator />
            </div>
        </Animation>
    );
};
