import React, { createContext, useContext, useState, ReactNode } from 'react';

interface AbortControllerContextType {
    currentController: AbortController | null;
    setController: (controller: AbortController) => void;
    resetController: () => void;
}

const AbortControllerContext = createContext<AbortControllerContextType>({
    currentController: null,
    setController: () => {},
    resetController: () => {},
});

export const useAbortController = () => useContext(AbortControllerContext);

interface AbortControllerProviderProps {
    children: ReactNode;
}

export const AbortControllerProvider: React.FC<AbortControllerProviderProps> = ({ children }) => {
    const [currentController, setCurrentController] = useState<AbortController | null>(null);

    const setController = (controller: AbortController) => {
        setCurrentController(controller);
    };

    const resetController = () => {
        setCurrentController(null);
    };

    return (
        <AbortControllerContext.Provider value={{ currentController, setController, resetController }}>
            {children}
        </AbortControllerContext.Provider>
    );
};
