import React, { useState, ChangeEvent, useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '../../../redux/app/hooks';
import { RootState } from '../../../redux/app/store';
import { ChatService } from '../../../libs/services/ChatService';
import { useOktaAuth } from '@okta/okta-react';
import { Button, makeStyles, mergeClasses, shorthands } from '@fluentui/react-components';
import { Breakpoints } from '../../../styles';
import { updateMessageSatisfied } from '../../../redux/features/conversations/conversationsSlice';
import { styled } from '@mui/material/styles';
import { TextField } from '@mui/material';

interface FeedbackProps {
    messageUuid: string;
    feedback: boolean | null;
    setFeedback: (satisfied: boolean | null) => void;
}

const useClasses = makeStyles({
    textarea: {
        display: 'flex',
        flexDirection: 'row',
        marginLeft: 'auto',
        position: 'relative',
        right: '0',
        bottom: '10px',
        zIndex: '100',
        '& button': {
            position: 'absolute',
            right: '0',
            top: '50%',
            transform: 'translateY(-50%)',
            zIndex: '1',
        },
    },
    btnSelect: {
        ...shorthands.borderRadius('20px'),
        ...shorthands.border('1px', 'solid', '#9CB0EC'),
        ...shorthands.padding('4px', '16px'),
        maxWidth: '163px',
        color: '#576DDD',
        fontWeight: '400',
        ...shorthands.gap('5px'),
        marginRight: '5px',
        marginBottom: '5px',
        whiteSpace: 'nowrap',
        cursor: 'pointer',
    },
    selected: {
        ...shorthands.borderColor('#576DDD'),
        color: '#fff',
        backgroundColor: '#576DDD',
    },
    reviewParent: {
        display: 'flex',
        flexWrap: 'wrap',
        ...Breakpoints.small({
            flexDirection: 'column',
        }),
    },
    textField: {
        maxHeight: '230px',
        ...shorthands.padding('12px', '170px', '12px', '12px'),
        ...shorthands.borderRadius('4px', '4px', '4px', '4px'),
        ...shorthands.border('1px', 'solid', '#1A1D20'),
        '&:focus': {
            ...shorthands.borderColor('#9CB0EC'),
            ...shorthands.outline('0'),
        },
    },
    btn: {
        backgroundColor: '#576DDD',
        ...shorthands.border('0'),
        ...shorthands.borderRadius('50%'),
        maxWidth: '32px',
        minWidth: '32px',
        width: '32px',
        maxHeight: '32px',
        minHeight: '32px',
        height: '32px',
        marginRight: '7px',
        '&:hover': {
            backgroundColor: '#576DDD',
            opacity: '0.7',
            '&:active': {
                backgroundColor: '#576DDD',
                opacity: '0.7',
            },
        },
        '&:active': {
            backgroundColor: '#576DDD',
            opacity: '0.7',
        },
    },
});

const SendIcon = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.14645 3.5607L2 8.70714L1.29289 8.00004L7.64645 1.64648L14 8.00004L13.2929 8.70714L8.14645 3.5607L8.14645 14.3536L7.14645 14.3536L7.14645 3.5607Z"
            fill="white"
        />
    </svg>
);

const CssTextField = styled(TextField)({
    width: '100%',
    '& label': {
        color: '#54616D',
        top: '-3px',
        '@media (max-width: 744px)': {
            fontSize: '15px',
        },
    },
    '& label.Mui-focused': {
        color: '#1D1D1D',
        top: '0',
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: '#8F8A80',
    },
    '& .MuiOutlinedInput-root': {
        borderRadius: '24px',
        background: '#fff',
        '& fieldset': {
            borderColor: '#8F8A80',
        },
        '&:hover fieldset': {
            borderColor: '#8F8A80',
        },
        '&.Mui-focused fieldset': {
            borderWidth: '1px',
            borderColor: '#8F8A80',
        },
    },
    '& .MuiInputBase-input': {
        paddingRight: '40px',
        paddingTop: '12.5px',
        paddingBottom: '12.5px',
        paddingLeft: '14px',
    },
});

const Feedback: React.FC<FeedbackProps> = ({ messageUuid, feedback, setFeedback }) => {
    const classes = useClasses();
    const [reasons, setReasons] = useState<string[]>([]);
    const [comments, setComments] = useState<string>('');
    const [feedbackSubmitted, setFeedbackSubmitted] = useState<boolean>(false);
    const chatService = new ChatService();
    const { selectedId } = useAppSelector((state: RootState) => state.conversations);
    const { oktaAuth } = useOktaAuth();
    const dispatch = useAppDispatch();

    const feedbackRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (feedback !== null && feedbackRef.current) {
            feedbackRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [feedback]);

    useEffect(() => {
        setReasons([]);
        setComments('');
    }, [feedback]);

    const handleReasonChange = (reason: string) => {
        setReasons((prevReasons) =>
            prevReasons.includes(reason) ? prevReasons.filter((r) => r !== reason) : [...prevReasons, reason],
        );
    };

    const handleCommentChange = (event: ChangeEvent<HTMLInputElement>) => {
        setComments(event.target.value);
    };

    const handleSubmit = () => {
        const requestBody = {
            conversation_uuid: selectedId,
            message_uuid: messageUuid,
            satisfied: feedback,
            reasons: reasons,
            comments: comments,
        };
        const accessToken = oktaAuth.getAccessToken() ?? '';
        const idToken = oktaAuth.getIdToken() ?? '';
        void chatService.createFeedback(requestBody, accessToken, idToken);

        dispatch(updateMessageSatisfied({ satisfied: Boolean(feedback), chatId: selectedId, uuid: messageUuid }));

        setFeedbackSubmitted(true);
        setFeedback(null);
        setReasons([]);
        setComments('');
    };

    const positiveReasons = ["That's exactly what I asked for", 'I love the style', 'Others'];
    const negativeReasons = ['That’s not what I asked', 'Incorrect answer', 'Not the expected style', 'Others'];

    if (feedback === null && !feedbackSubmitted) {
        return null;
    }

    return (
        <div ref={feedbackRef} style={{ marginTop: '10px', position: 'relative' }}>
            <div
                style={{ position: 'absolute', right: '10px', top: '15px', fontSize: '24px', cursor: 'pointer' }}
                onClick={() => {
                    setFeedbackSubmitted(false);
                    setFeedback(null);
                    setReasons([]);
                    setComments('');
                }}
            >
                &times;
            </div>
            {!feedbackSubmitted ? (
                <div
                    style={{
                        border: '1px solid #9AA0A0',
                        borderRadius: '5px',
                        padding: '15px',
                        fontSize: '12px',
                    }}
                >
                    {feedback !== null && (
                        <>
                            <div>
                                <p style={{ color: '#54616D', fontSize: '14px', marginTop: '0' }}>
                                    Why did you choose this evaluation?
                                </p>
                                <div className={classes.reviewParent}>
                                    {feedback
                                        ? positiveReasons.map((reason) => (
                                              <label
                                                  key={reason}
                                                  htmlFor={reason}
                                                  className={
                                                      reasons.includes(reason)
                                                          ? mergeClasses(classes.btnSelect, classes.selected)
                                                          : classes.btnSelect
                                                  }
                                              >
                                                  <input
                                                      style={{ display: 'none' }}
                                                      type="checkbox"
                                                      id={reason}
                                                      value={reason}
                                                      checked={reasons.includes(reason)}
                                                      onChange={() => {
                                                          handleReasonChange(reason);
                                                      }}
                                                  />
                                                  {reason}
                                              </label>
                                          ))
                                        : negativeReasons.map((reason) => (
                                              <label
                                                  key={reason}
                                                  htmlFor={reason}
                                                  className={
                                                      reasons.includes(reason)
                                                          ? mergeClasses(classes.btnSelect, classes.selected)
                                                          : classes.btnSelect
                                                  }
                                              >
                                                  <input
                                                      style={{ display: 'none' }}
                                                      type="checkbox"
                                                      id={reason}
                                                      value={reason}
                                                      checked={reasons.includes(reason)}
                                                      onChange={() => {
                                                          handleReasonChange(reason);
                                                      }}
                                                  />
                                                  {reason}
                                              </label>
                                          ))}
                                </div>
                            </div>
                            <div style={{ marginTop: '30px' }}>
                                <div className={classes.textarea}>
                                    <CssTextField
                                        className={classes.textField}
                                        variant="outlined"
                                        label="Share other comments (Optional)"
                                        value={comments}
                                        onChange={handleCommentChange}
                                    />
                                    {/*<input*/}
                                    {/*    style={{*/}
                                    {/*        resize: 'none',*/}
                                    {/*        width: '100%',*/}
                                    {/*        padding: '10px 30px 10px 15px',*/}
                                    {/*        fontSize: '14px',*/}
                                    {/*    }}*/}
                                    {/*    value={comments}*/}
                                    {/*    onChange={handleCommentChange}*/}
                                    {/*></input>*/}
                                    <Button
                                        title="Submit"
                                        aria-label="Submit message"
                                        appearance="transparent"
                                        className={classes.btn}
                                        icon={<SendIcon />}
                                        disabled={reasons.length === 0}
                                        onClick={handleSubmit}
                                    />
                                </div>
                            </div>
                        </>
                    )}
                </div>
            ) : (
                <div
                    style={{
                        border: '1px solid #9AA0A0',
                        borderRadius: '5px',
                        padding: '15px 30px 15px 15px',
                    }}
                >
                    Thank you for your comments!
                </div>
            )}
        </div>
    );
};

export default Feedback;
