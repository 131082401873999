import botIcon1 from './assets/bot-icons/bot-icon-1.png';
import { MODEL_NAME } from './redux/features/conversations/ConversationsState';
import style3d from './assets/images/style/3d.png';
import styleAcrylicAndOil from './assets/images/style/acrylic-and-oil.png';
import styleDigitalIllustration from './assets/images/style/digital-illustration.png';
import styleDramaticLightning from './assets/images/style/dramatic-lightning.png';
import styleGeometric from './assets/images/style/geometric.png';
import styleLandscape from './assets/images/style/landscape.png';
import styleNeon from './assets/images/style/neon.png';
import stylePencil from './assets/images/style/pencil.png';
import styleRealisticPhoto from './assets/images/style/realistic-photo.png';
import styleVectorStyle from './assets/images/style/vector-style.png';
import styleWatercolor from './assets/images/style/watercolor.png';

export const Constants = {
    app: {
        name: 'Conversation',
        updateCheckIntervalSeconds: 60 * 5,
        CONNECTION_ALERT_ID: 'connection-alert',
        importTypes: '.txt,.pdf,.docx, .xlsx, .pptx, .md,.jpg,.jpeg,.png,.tif,.tiff,.bmp,.gif',
    },
    bot: {
        profile: {
            id: 'bot',
            fullName: 'Conversation',
            emailAddress: '',
            photo: botIcon1,
        },
        fileExtension: 'skcb',
        typingIndicatorTimeoutMs: 5000,
    },
};

export const modelNames = Object.values(MODEL_NAME);

export const modelLabels: { [key in MODEL_NAME]: string } = {
    [MODEL_NAME.GEMINI_PRO]: 'Google Gemini 1.5 Pro',
    [MODEL_NAME.AZURE_TURBO]: 'OpenAI GPT 4o',
};
export const modelSubLabels: { [key in MODEL_NAME]: string } = {
    [MODEL_NAME.GEMINI_PRO]: 'Powerful for querying documents',
    [MODEL_NAME.AZURE_TURBO]: 'More creative',
};

export const WelcomeMessage = `
Hello 👋, I am MaIA and I can do many **NEW** things! Among other things:

* I can answer your questions using information from web pages you give me.
* I'm always up-to-date with the latest info.
* I'm faster than ever.
* I switch on-demand from one model to another so you can choose your preferred answer.

So how can I help ?
`;
export const WelcomeMessageImage = `
Hello, I am MaIA , please describe the image you would like to create.
`;

export const formatLabels: Record<string, string> = {
    '1:1': 'Square (1:1)',
    '9:16': 'Vertical (9:16)',
    '16:9': 'Widescreen (16:9)',
    '3:4': 'Portrait (3:4)',
    '4:3': 'Landscape (4:3)',
};

export const formats = ['1:1', '9:16', '16:9', '3:4', '4:3'];

export const styleImageWithLabels: Record<string, { image: string; label: string }> = {
    '3d': {
        image: style3d,
        label: '3D',
    },
    'acrylic-and-oil': {
        image: styleAcrylicAndOil,
        label: 'Acrylic And Oil',
    },
    'digital-illustration': {
        image: styleDigitalIllustration,
        label: 'Digital Illustration',
    },
    'dramatic-lightning': {
        image: styleDramaticLightning,
        label: 'Dramatic Lightning',
    },
    geometric: {
        image: styleGeometric,
        label: 'Geometric',
    },
    landscape: {
        image: styleLandscape,
        label: 'Landscape',
    },
    neon: {
        image: styleNeon,
        label: 'Neon',
    },
    pencil: {
        image: stylePencil,
        label: 'Pencil',
    },
    'realistic-photo': {
        image: styleRealisticPhoto,
        label: 'Realistic Photo',
    },
    'vector-style': {
        image: styleVectorStyle,
        label: 'Vector Style',
    },
    watercolor: {
        image: styleWatercolor,
        label: 'Watercolor',
    },
};

export const angles = [
    'wide-angle',
    'bird-eye',
    'close-up',
    'extreme-close-up',
    'medium-shot',
    'long-shot',
    'high-angle',
    'low-angle',
    'dutch-angle',
    'eye-level-shot',
    'tilted-shot',
];

export const anglesLabels: Record<string, string> = {
    'wide-angle': 'Wide Angle',
    'bird-eye': 'Bird Eye',
    'close-up': 'Close Up',
    'extreme-close-up': 'Extreme Close Up',
    'medium-shot': 'Medium Shot',
    'long-shot': 'Long Shot',
    'high-angle': 'High Angle',
    'low-angle': 'Low Angle',
    'dutch-angle': 'Dutch Angle',
    'eye-level-shot': 'Eye Level Shot',
    'tilted-shot': 'Tilted Shot',
};
