import { makeStyles, mergeClasses, shorthands, Text, tokens } from '@fluentui/react-components';
import { FC, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../redux/app/hooks';
import { setSelectedConversation } from '../../../redux/features/conversations/conversationsSlice';
import { Breakpoints, SharedStyles } from '../../../styles';
import { EditChatName } from '../shared/EditChatName';
import { ListItemActions } from './ListItemActions';

import { setIsOpenMobileMenu, setSelectedTab } from '../../../redux/features/app/appSlice';
import { RootState } from '../../../redux/app/store';

const useClasses = makeStyles({
    root: {
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        cursor: 'pointer',
        minHeight: '44px',
        ...shorthands.padding(0, tokens.spacingHorizontalM),
        ...Breakpoints.small({
            width: '90%',
            ...shorthands.padding(0, tokens.spacingHorizontalMNudge),
        }),
        '&:hover': {
            backgroundColor: '#F7F3ED',
        },
    },
    actions: {
        display: 'none',
    },
    avatar: {
        flexShrink: 0,
        width: '32px',
    },
    body: {
        minWidth: 0,
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        marginLeft: tokens.spacingHorizontalXS,
        alignSelf: 'center',
    },
    header: {
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    title: {
        ...SharedStyles.overflowEllipsis,
        fontSize: tokens.fontSizeBase300,
        color: '#1A1D20',
    },
    timestamp: {
        flexShrink: 0,
        marginLeft: tokens.spacingHorizontalM,
        fontSize: tokens.fontSizeBase200,
        color: '#000',
        lineHeight: tokens.lineHeightBase200,
    },
    previewText: {
        ...SharedStyles.overflowEllipsis,
        display: 'block',
        lineHeight: tokens.lineHeightBase100,
        color: '#000',
    },
    popoverSurface: {
        display: 'none',
    },
    selected: {
        backgroundColor: '#F7F3ED',
        boxShadow: '#8F8A80 5px 0px 0px -1px inset',
    },
    disabled: {
        cursor: 'not-allowed',
        '& > *': {
            cursor: 'not-allowed',
        },
    },
    protectedIcon: {
        color: tokens.colorPaletteLightGreenBorder1,
        verticalAlign: 'text-bottom',
        marginLeft: tokens.spacingHorizontalXS,
    },
    hoverActions: {
        display: 'flex',
        alignItems: 'center',
    },
    itemArea: {
        display: 'flex',
    },
    hovered: {
        width: '85%',
    },
});

interface IChatListItemProps {
    id: string;
    header: string;
    isSelected: boolean;
    isEditing: boolean;
    disabled: boolean;
    setDisabled: (value: boolean) => void;
    onEditClick: () => void;
}

export const ChatListItem: FC<IChatListItemProps> = ({ id, header, isSelected, isEditing, disabled, onEditClick }) => {
    const classes = useClasses();
    const dispatch = useAppDispatch();
    // const { selectedTab } = useAppSelector((state: RootState) => state.app);
    const { isOpenMobileMenu } = useAppSelector((state: RootState) => state.app);

    const [showActions, setShowActions] = useState(false);

    const onClick = (_ev: any) => {
        dispatch(setIsOpenMobileMenu(!isOpenMobileMenu));
        dispatch(setSelectedConversation(id));
        dispatch(setSelectedTab('chat'));
    };

    const handleExitEdits = () => {
        onEditClick();
    };

    return (
        <div
            className={mergeClasses(classes.root, isSelected && classes.selected, disabled ? classes.disabled : '')}
            onMouseEnter={() => {
                setShowActions(true);
            }}
            onMouseLeave={() => {
                setShowActions(false);
            }}
            title={`Chat: ${header}`}
            aria-label={`Chat list item: ${header}`}
        >
            <div
                className={mergeClasses(
                    classes.itemArea,
                    showActions && !isEditing ? classes.hovered : '',
                    isSelected && !isEditing ? classes.hovered : '',
                )}
                onClick={onClick}
            >
                {!isEditing && (
                    <div className={classes.body}>
                        <div className={classes.header}>
                            <Text className={classes.title} title={header}>
                                {header}
                            </Text>
                        </div>
                    </div>
                )}
            </div>
            {!isEditing && (isSelected || showActions) && !disabled && (
                <div className={mergeClasses(classes.actions, classes.hoverActions)}>
                    <ListItemActions chatId={id} onEditTitleClick={onEditClick} />
                </div>
            )}
            {isEditing && <EditChatName name={header} chatId={id} exitEdits={handleExitEdits} />}
        </div>
    );
};
