import { makeStyles, mergeClasses, shorthands, tokens } from '@fluentui/react-components';
import { FC } from 'react';
import { Breakpoints, SharedStyles } from '../../../styles';
import { setIsOpenMobileMenu, setSelectedTab } from '../../../redux/features/app/appSlice';
import { useAppDispatch, useAppSelector } from '../../../redux/app/hooks';
import { setGallery } from '../../../redux/features/images/imagesSlice';
import { RootState } from '../../../redux/app/store';
import Typography from '@mui/material/Typography';

const useClasses = makeStyles({
    root: {
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        cursor: 'pointer',
        minHeight: '48px',
        ...shorthands.padding(tokens.spacingVerticalS, tokens.spacingHorizontalXL),
        ...Breakpoints.small({
            width: '90%',
            ...shorthands.padding(tokens.spacingVerticalS, tokens.spacingHorizontalMNudge),
        }),
    },
    actions: {
        display: 'none',
    },
    avatar: {
        flexShrink: 0,
        width: '32px',
    },
    body: {
        minWidth: 0,
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        marginLeft: tokens.spacingHorizontalXS,
        alignSelf: 'center',
    },
    header: {
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    title: {
        ...SharedStyles.overflowEllipsis,
        fontSize: tokens.fontSizeBase300,
        fontWeight: tokens.fontWeightBold,
        color: '#1A1D20',
        display: 'flex',
        alignItems: 'center',
    },
    timestamp: {
        flexShrink: 0,
        marginLeft: tokens.spacingHorizontalM,
        fontSize: tokens.fontSizeBase200,
        color: tokens.colorNeutralForeground2,
        lineHeight: tokens.lineHeightBase200,
    },
    previewText: {
        ...SharedStyles.overflowEllipsis,
        display: 'block',
        lineHeight: tokens.lineHeightBase100,
        color: tokens.colorNeutralForeground2,
    },
    popoverSurface: {
        display: 'none',
    },
    selected: {
        backgroundColor: '#F7F3ED',
        boxShadow: '#8F8A80 5px 0px 0px -1px inset',
    },
    protectedIcon: {
        color: tokens.colorPaletteLightGreenBorder1,
        verticalAlign: 'text-bottom',
        marginLeft: tokens.spacingHorizontalXS,
    },
    hoverActions: {
        display: 'flex',
    },
    itemArea: {
        display: 'flex',
    },
    hovered: {
        width: '70%',
    },
});

interface IImagesListItemProps {
    isSelected: boolean;
    title: string;
    icon: () => JSX.Element;
    tabSelectedName: string;
}

export const ImagesListItem: FC<IImagesListItemProps> = ({ isSelected, title, icon, tabSelectedName }) => {
    const { selectedTab } = useAppSelector((state: RootState) => state.app);
    const classes = useClasses();
    const dispatch = useAppDispatch();
    const { isOpenMobileMenu } = useAppSelector((state: RootState) => state.app);

    return (
        <div
            className={mergeClasses(classes.root, isSelected && classes.selected)}
            onClick={() => {
                if (tabSelectedName === selectedTab) {
                    return;
                }
                dispatch(setGallery(undefined));
                dispatch(setSelectedTab(tabSelectedName));
                dispatch(setIsOpenMobileMenu(!isOpenMobileMenu));
            }}
        >
            <div className={mergeClasses(classes.itemArea, isSelected ? classes.hovered : '')}>
                <div className={classes.body}>
                    <div className={classes.header}>
                        <Typography
                            className={classes.title}
                            sx={{ fontSize: '16px', fontWeight: '700', textTransform: 'uppercase' }}
                        >
                            <span style={{ marginRight: '5px', display: 'flex' }}>{icon()}</span>
                            {title}
                        </Typography>
                    </div>
                </div>
            </div>
        </div>
    );
};
