import { useOktaAuth } from '@okta/okta-react';
import { useEffect } from 'react';
import { ChatView, Error as MyError, Loading } from '../components/views';
import { addAlert, setActiveUserInfo, setAppState, setIsOpenMobileMenu } from '../redux/features/app/appSlice';
import { useDispatch } from 'react-redux';
import { useAppDispatch, useAppSelector } from '../redux/app/hooks';
import { RootState } from '../redux/app/store';

import { Image, makeStyles, Portal, shorthands, tokens } from '@fluentui/react-components';
// import { UserSettingsMenu } from '../components/header/UserSettingsMenu';
import { useChat } from '../libs/hooks';
import { EAppState } from '../redux/features/app/AppState';
import { Breakpoints } from '../styles';

import logo from '../assets/images/LogoNew.svg';
import menu from '../assets/images/Menu.svg';
import close from '../assets/images/Close.svg';
import { jwtDecode } from 'jwt-decode';
import { initializeApp } from 'firebase/app';

import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { AlertType } from '../libs/models/AlertType';
import { SplashScreens } from '../components/views/SplashScreens';

export const useClasses = makeStyles({
    container: {
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        width: '100%',
        ...shorthands.overflow('hidden'),
        '@media (max-width: 1000px)': {
            height: '100dvh',
        },
    },
    headerMobile: {
        alignItems: 'center',
        backgroundColor: '#fff',
        color: '#000',
        '& h1': {
            paddingLeft: tokens.spacingHorizontalXL,
            display: 'flex',
        },
        height: '80px',
        justifyContent: 'space-between',
        ...shorthands.padding('0', '15px'),
        display: 'none',
        ...Breakpoints.small({
            display: 'flex',
        }),
    },
    persona: {
        marginRight: tokens.spacingHorizontalXXL,
    },
});

interface DecodedToken {
    ver: number;
    jti: string;
    iss: string;
    aud: string;
    iat: number;
    exp: number;
    cid: string;
    uid: string;
    scp: string[];
    auth_time: number;
    sub: string;
    countryCode?: string; // countryCode might be undefined
}

const Home = () => {
    const classes = useClasses();
    const { authState, oktaAuth } = useOktaAuth();
    const dispatch = useDispatch();
    const { activeUserInfo, appState } = useAppSelector((state: RootState) => state.app);
    const { conversations } = useAppSelector((state: RootState) => state.conversations);

    const chat = useChat();

    useEffect(() => {
        const token = oktaAuth.getAccessToken() as string;
        if (token) {
            const decodedToken: DecodedToken = jwtDecode(token);

            if (decodedToken.countryCode && decodedToken.countryCode === 'CN') {
                dispatch(setAppState(EAppState.SigningOut));
                // eslint-disable-next-line @typescript-eslint/no-floating-promises
                oktaAuth.signOut();
            }
        }
    }, [oktaAuth]);

    useEffect(() => {
        if (appState !== EAppState.Chat) {
            return;
        }

        if (!('serviceWorker' in navigator)) {
            console.log('Service workers are not supported in this browser.');
            return;
        }

        if (!('Notification' in window)) {
            console.log('Notifications are not supported in this browser.');
            return;
        }

        const firebaseConfig = {
            apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
            authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
            projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
            storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
            messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
            appId: process.env.REACT_APP_FIREBASE_APP_ID,
        };

        const app = initializeApp(firebaseConfig);

        const messaging = getMessaging(app);

        const registerToken = function () {
            getToken(messaging, { vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY })
                .then((currentToken) => {
                    if (currentToken) {
                        const apiUrl = `${process.env.REACT_APP_BACKEND_URI ?? ''}api/v1/tokens`;

                        const headers = new Headers();

                        const accessToken = oktaAuth.getAccessToken() ?? '';

                        headers.append('Content-Type', `application/json`);

                        if (accessToken) {
                            headers.append('Authorization', `Bearer ${accessToken}`);
                        }

                        fetch(apiUrl, {
                            method: 'POST',
                            body: JSON.stringify({ fcm_token: currentToken }),
                            headers,
                        })
                            .then((response) => {
                                if (!response.ok) {
                                    throw new Error('Could not find root element');
                                }
                                return response.json();
                            })
                            // .then((data) => {
                            //     console.log(data);
                            // })
                            .catch((error: unknown) => {
                                console.error('Error:', error);
                            });
                    } else {
                        console.log('No registration token available. Request permission to generate one.');
                    }
                })
                .catch((err: unknown) => {
                    console.log('An error occurred while retrieving token. ', err);
                });
        };

        onMessage(messaging, (payload) => {
            if (payload.notification) {
                dispatch(
                    addAlert({
                        type: AlertType.Info,
                        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition, @typescript-eslint/restrict-template-expressions
                        message: `${payload.notification.title} ${payload.notification.body}`.trim(),
                    }),
                );
            } else if (payload.data) {
                dispatch(
                    addAlert({
                        type: AlertType.Info,
                        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition, @typescript-eslint/restrict-template-expressions
                        message: `${payload.data.document} ${payload.data.status}`.trim(),
                    }),
                );
            }
        });

        function requestPermission() {
            console.log('Requesting permission...');
            void Notification.requestPermission().then((permission) => {
                if (permission === 'granted') {
                    console.log('Notification permission granted.');
                    registerToken();
                } else {
                    console.log('Unable to get permission to notify.');
                }
            });
        }

        requestPermission();
    }, [appState, oktaAuth]);

    useEffect(() => {
        if (appState === EAppState.SigningOut) {
            return;
        }
        const handleMessage = (event: MessageEvent<{ type: string; payload: any }>) => {
            if (event.data.type === 'FIREBASE_MESSAGE') {
                const payload = event.data.payload;
                console.log('Received message:', payload);
            }
        };

        window.addEventListener('message', handleMessage);

        return () => {
            window.removeEventListener('message', handleMessage);
        };
    }, [appState]);

    useEffect(() => {
        if (appState === EAppState.SigningOut) {
            return;
        }

        if (Object.keys(conversations).length && appState !== EAppState.Chat) {
            dispatch(setAppState(EAppState.Chat));
        }
    }, [conversations, appState]);

    useEffect(() => {
        if (appState === EAppState.SigningOut) {
            return;
        }

        if (authState?.isAuthenticated && appState === EAppState.SettingUserInfo) {
            oktaAuth
                .getUser()
                .then((info) => {
                    dispatch(
                        setActiveUserInfo({
                            id: info.sub,
                            email: info.preferred_username ?? '',
                            username: `${info.given_name ?? ''} ${info.family_name ?? ''}`.trim(),
                        }),
                    );
                    dispatch(setAppState(EAppState.LoadingChats));
                })
                .catch(() => {
                    dispatch(setAppState(EAppState.ErrorLoadingUserInfo));
                });
        }

        if (authState?.isAuthenticated && appState === EAppState.LoadingChats) {
            void Promise.all([
                chat
                    .loadChats()
                    .then(() => {
                        dispatch(setAppState(EAppState.Chat));
                    })
                    .catch(() => {
                        dispatch(setAppState(EAppState.ErrorLoadingChats));
                    }),
            ]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authState?.isAuthenticated, appState]);

    if (!authState) {
        return <div>Loading...</div>;
    }

    return (
        <div>
            <div>
                {!authState.isAuthenticated && appState === EAppState.SigningOut && (
                    <Loading text="Signing you out..." />
                )}

                {authState.isAuthenticated && activeUserInfo && <Chat classes={classes} />}

                {!authState.isAuthenticated && appState !== EAppState.SigningOut && <SplashScreens />}
            </div>
        </div>
    );
};

const Chat = ({ classes }: { classes: ReturnType<typeof useClasses> }) => {
    const { appState } = useAppSelector((state: RootState) => state.app);
    const dispatch = useAppDispatch();
    const { isOpenMobileMenu } = useAppSelector((state: RootState) => state.app);

    return (
        <div className={classes.container}>
            <Portal>
                <div className={classes.headerMobile}>
                    <div
                        style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', zIndex: '1001' }}
                        onClick={() => {
                            dispatch(setIsOpenMobileMenu(!isOpenMobileMenu));
                        }}
                    >
                        {isOpenMobileMenu ? (
                            <Image src={close} style={{ maxWidth: '100px', width: '100%' }} fit="center" />
                        ) : (
                            <Image src={menu} style={{ maxWidth: '100px', width: '100%' }} fit="center" />
                        )}
                        <strong style={{ marginLeft: '5px' }}>{isOpenMobileMenu ? 'Close' : 'Menu'}</strong>
                    </div>
                    <div style={{ maxWidth: 170 }}>
                        <Image src={logo} style={{ maxWidth: '100px', width: '100%' }} fit="cover" />
                    </div>
                </div>
            </Portal>
            {appState === EAppState.SettingUserInfo && (
                <>
                    <Loading text={'Hang tight while we fetch your information...'} />
                </>
            )}
            {appState === EAppState.ErrorLoadingUserInfo && (
                <MyError text={'Unable to load user info. Please try signing out and signing back in.'} />
            )}
            {appState === EAppState.ErrorLoadingChats && (
                <MyError text={'Unable to load MaIA. Please try refreshing the page.'} />
            )}
            {appState === EAppState.LoadingChats && <Loading text="MaIA is loading..." />}
            {appState === EAppState.Chat && <ChatView />}
        </div>
    );
};

export default Home;
