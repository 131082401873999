import * as React from 'react';
import { makeStyles, tokens } from '@fluentui/react-components';
import { getFriendlyChatName } from '../../../libs/hooks/useChat';
import { useAppSelector } from '../../../redux/app/hooks';
import { RootState } from '../../../redux/app/store';
import { Conversations } from '../../../redux/features/conversations/ConversationsState';
import { ChatListItem } from './ChatListItem';
import { useState } from 'react';

const useClasses = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingBottom: tokens.spacingVerticalXS,
    },
});

interface IChatListSectionProps {
    conversations: Conversations;
    setDisabled: (value: boolean) => void;
    disabled: boolean;
}

export const ChatListSection: React.FC<IChatListSectionProps> = ({ conversations, setDisabled, disabled }) => {
    const classes = useClasses();
    const { selectedId } = useAppSelector((state: RootState) => state.conversations);
    const keys = Object.keys(conversations);

    const [editingChatId, setEditingChatId] = useState<string | null>(null);

    const handleEditClick = (chatId: string) => {
        // Corrected the handleEditClick function signature
        setEditingChatId(chatId === editingChatId ? null : chatId);
    };

    return keys.length > 0 ? (
        <div className={classes.root}>
            {keys.map((id) => {
                const convo = conversations[id];
                const isSelected = id === selectedId;
                const isEditing = editingChatId === id;

                return (
                    <ChatListItem
                        id={id}
                        key={id}
                        isSelected={isSelected}
                        isEditing={isEditing}
                        header={getFriendlyChatName(convo)}
                        disabled={disabled}
                        setDisabled={setDisabled}
                        onEditClick={() => {
                            handleEditClick(id);
                        }}
                    />
                );
            })}
        </div>
    ) : null;
};
